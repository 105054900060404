<template>
  <v-sheet outlined rounded min-height="100%">
    <Loader v-if="loading" />
    <div v-else>
      <v-card-title>Транзакции компании "{{ company_name }}"</v-card-title>
      <!-- {{ companies }} -->
      <div class="mt-5">
        <div class="d-flex flex-row justify-start align-center ml-3 mr-3">
          <v-text-field
            type="date"
            label="Дата с:"
            v-model="date_from"
            style="max-width: 218px;"
            class="mr-4"
          ></v-text-field>
          <v-text-field
            type="date"
            label="Дата по:"
            v-model="date_to"
            style="max-width: 218px;"
          ></v-text-field>
          <v-btn @click="filterReportOperations" class="ml-auto" color="success" dark>Применить фильтры</v-btn>
        </div>
        <div>
          <RefNavigator 
            :items="filteredOperations"
            :headers="headers"
          />
        </div>
      </div>
      <div class="pa-3 d-flex justify-end">
        <v-btn @click="send_report" color="success" dark>Скачать отчет</v-btn>
      </div>
    </div>
    <!-- <router-view v-if="!loading"/> -->
  </v-sheet>
</template>


<script>

import RefNavigator from '../components/RefNavigator.vue'
import Loader from '../components/ui/Loader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Transactions',
  components: {
    RefNavigator, Loader
  },
  data: () => ({
    operations: [],
    date_from: undefined,
    date_to: undefined,
    loading: true,
    company_name: ''
  }),
  async mounted() {
    try {
      this.company_name = (await this.$store.dispatch('get_user_company')).name
    } catch(e) {
      console.error(e)
    }

    // Даты
    let date = new Date()
    this.date_to = date.toISOString().substr(0, 10)
    date.setDate(-30)
    this.date_from = date.toISOString().substr(0, 10)
    try {
      let ops = await this.$store.dispatch('get_report_operations', this.filters)
      this.operations = [...ops]
    } catch(e) {
      console.error(e)
      this.operations = []
    }
    this.loading = false
  },
  computed: {
    ...mapGetters(['user', ]),

    filteredOperations() {
      let result = [...this.operations]
      result.map(elem => {
        if(elem.type == 'ACCRUAL') {
          elem.type = 'Начисление'
        } else if(elem.type == 'WRITEOFF') {
          elem.type = 'Списание'
          elem.sum = (-1)*elem.sum
        } else if(elem.type == 'CORRECTION') {
          elem.type = 'Изъятие'
          elem.sum = (-1)*elem.sum
        }
        // let date = new Date(elem.date)
        // elem.date_formatted = date.toLocaleDateString()
      })
      
      return result
    },

    headers() {
      return [
        {
            text  : '#',
            align : 'start',
            value : 'id'
          },
          {
            text  : 'Дата',
            value : 'date'
          },
          {
            text  : 'Время',
            value : 'time'
          },
          {
            text  : 'Тип операции',
            value : 'type'
          },
          {
            text  : 'Комментарий',
            value : 'comment'
          },
          {
            text  : 'Сумма',
            value : 'sum'
          },
      ]
    },
    filters() {
      return {
        company_id: this.user.company_id,
        date_from: this.date_from,
        date_to: this.date_to
      }
    }
  },
  methods: {
    async filterOperations() {
      this.operations = [...(await this.$store.dispatch('get_report_operations', this.filters))]
    },
    async filterReportOperations() {
      this.operations = [...(await this.$store.dispatch('get_report_operations', this.filters))]
    },
    async send_report() {
      await this.$store.dispatch('get_report', this.filters)
    },
  },
}
</script>